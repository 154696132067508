import React, { useEffect, useState, useRef, useCallback } from 'react';
import CustomCursor from "./components/CustomCursor";
import Article from "./components/Article";
// import useFamilyHover from './hooks/useFamilyHover';

function Home() {
	// useFamilyHover();

	const slides = [
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hero-top-gob.webp', alt: '' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hero-lobster.webp', alt: '' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hero-butt.webp', alt: '' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hero-inside.webp', alt: '' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hero-chickens.webp', alt: '' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hero-party.webp', alt: '' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hero-blast.webp', alt: '' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hero-lev.webp', alt: '' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hero-waldragon.webp', alt: '' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hero-dragon.webp', alt: '' },
	];

	const [currentSlide, setCurrentSlide] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentSlide((currentSlide) => (currentSlide + 1) % slides.length);
		}, 8000);
		return () => clearInterval(interval);
	}, [slides.length]);

	const projects = [
		{ name: 'IlluminatiNFT', url: 'https://www.illuminatinft.com/', description: 'Innovation, Art, and Mischief are the bywords of this Secret Society. Enter the Circle.', social: 'secretsociety', marketplace: 'https://magiceden.io/collections/ethereum/0x8cb05890b7a640341069fb65dd4e070367f4d2e6', cta: 'illuminatinft.com', target: 'brand' },
		{ name: 'Leviathan\'s Children', url: 'https://www.the187.xyz/', description: 'The axle of a wheel spinning in infinite directions. Dive deep into this sprawling world.', social: 'the187', marketplace: 'https://magiceden.io/collections/ethereum/0x9ef8750c72061edbeeef4beb1aceee5b5a63748a', cta: 'the187.xyz', target: 'brand' },
		{ name: 'Goblintown', url: 'https://www.goblintown.wtf/', description: 'Any point can be a start, an end, or a continuation. This one is all of them. With farts.', social: 'goblintown', marketplace: 'https://magiceden.io/collections/ethereum/0x8c6def540b83471664edc6d5cf75883986932674', cta: 'goblintown.wtf', target: 'gob' },
		// { name: 'Formerly McGoblin Burger', url: 'https://lunch.biginc.business/', description: '...Of Goblintown\'s very own local eatery. Once a place of frolicking and stomach-turning joy, McGoblinburger was demolished by a rabble of hungry Goblintown denizens, but not before much of its stock was used to lure and capture...', social: 'McGoblinBurger', marketplace: 'https://magiceden.io/collections/ethereum/0xc5b52253f5225835cc81c52cdb3d6a22bc3b0c93', cta: 'lunch.biginc.business', target: 'gob' },
		// { name: 'Grumpls', url: 'https://goblintown.wtf/grumpl-artists', description: '...Hundreds of adorable - sometimes fearsome - ravenous little jellybags. Mostly friendly to goblinkind, the grumpls\' origins are unclear, but we know what happens when they get into the wrong hands...', social: 'goblintown', marketplace: 'https://magiceden.io/collections/ethereum/0x279c53df917579bb454b61555982dac1750187d9', cta: 'goblintown.wtf/grumpl-artists', target: 'gob' },
		{ name: 'BiG iNC', url: 'https://biginc.business', description: 'The nemesis of Goblintown\'s merry mayhem, this dour, Randian nightmare awaits.', social: 'biginc', marketplace: 'https://magiceden.io/collections/ethereum/0x895688bf87d73cc7da27852221799d31b027e300', cta: 'biginc.business', target: 'gob' },
	]

	const teammates = [
		{ src: 'i/team/ajt-pfp.png', alt: 'Alex Taub pfp', name: 'Alex Taub' },
		{ src: 'i/team/wirelyss-pfp.png', alt: 'Alyssa Surowiec pfp', name: 'Alyssa Surowiec' },
		{ src: 'i/team/bruce-pfp.png', alt: 'Bruce Seaton pfp', name: 'Bruce Seaton' },
		{ src: 'i/team/cesar-pfp.png', alt: 'Cesar Kuriyama pfp', name: 'Cesar Kuriyama' },
		{ src: 'i/team/hoshipeach-pfp.png', alt: 'Hoshi Peach pfp', name: 'Hoshi Peach' },
		{ src: 'i/team/jacky-pfp.png', alt: 'Jacky Sun pfp', name: 'Jacky Sun' },
		{ src: 'i/team/jonmacapodi-pfp.png', alt: 'Jon Macapodi pfp', name: 'Jon Macapodi' },
		{ src: 'i/team/kammy-pfp.png', alt: 'Kammy Nguyen pfp', name: 'Kammy Nguyen' },
		// { src: 'i/team/karan-pfp.png', alt: 'Karan Singh pfp', name: 'Karan Singh' },
		{ src: 'i/team/kazi-pfp.png', alt: 'Kazi Islam pfp', name: 'Kazi Islam' },
		{ src: 'i/team/mags-pfp.png', alt: 'Mags Sawicka pfp', name: 'Mags Sawicka' },
		{ src: 'i/team/matt-pfp.png', alt: 'Matt Banyard pfp', name: 'Matt Banyard' },
		{ src: 'i/team/michael-pfp.png', alt: 'Michael Schonfeld pfp', name: 'Michael Schonfeld' },
		// { src: 'i/team/monica-pfp.png', alt: 'Monica Rojas pfp', name: 'Monica Rojas' },
		{ src: 'i/team/nicky-pfp.png', alt: 'Nickydoodles pfp', name: 'Nickydoodles' },
		{ src: 'i/team/nifty-pfp.png', alt: 'NiftyLabs pfp', name: 'NiftyLabs' },
		{ src: 'i/team/processgrey-pfp.png', alt: 'process_grey pfp', name: 'process_grey' },
		{ src: 'i/team/sarah-pfp.png', alt: 'Sarah Wood pfp', name: 'Sarah Wood' },
		{ src: 'i/team/scott-pfp.png', alt: 'Scott Greskovic pfp', name: 'Scott Greskovic' },
		{ src: 'i/team/shonk-pfp.png', alt: 'Shonk pfp', name: 'Shonk' },
		{ src: 'i/team/steph-pfp.png', alt: 'Steph Volckmann pfp', name: 'Steph Volckmann' },
		// { src: 'i/team/sydney-pfp.png', alt: 'Sydney Clara Brafman pfp', name: 'Sydney Clara Brafman' },
		// { src: 'i/team/zhanna-pfp.png', alt: 'Zhanna Schonfeld pfp', name: 'Zhanna Schonfeld' },
	];

	const scrollRef = useRef();
	const requestRef = useRef();
	const [scrollPos, setScrollPos] = useState(0);
	const [direction, setDirection] = useState(1);

	const scroll = useCallback(() => {
		const maxScroll = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;

		setScrollPos((prevPos) => {
			let newPos = prevPos + direction * 0.125;
			if (newPos >= maxScroll || newPos <= 0) {
				setDirection(-direction);
			}
			return newPos;
		});

		requestRef.current = requestAnimationFrame(scroll);
	}, [direction]);

	useEffect(() => {
		scrollRef.current.scrollLeft = scrollPos;
	}, [scrollPos]);

	useEffect(() => {
		requestRef.current = requestAnimationFrame(scroll);
		return () => cancelAnimationFrame(requestRef.current);
	}, [scroll]);

	const aboutRef = useRef(null);

	const scrollToAbout = (e) => {
		e.preventDefault();
		scrollToRef(aboutRef);
	};

	function scrollToRef(ref) {
		window.scrollTo(0, ref.current.offsetTop);
	}

	return (
		<div id="truthArts" className='lander'>
			{/* <CustomCursor /> */}

			{/* hero */}
			<div id="hero" className="edge">
				{/* masthead */}
				<header id="head">
					<h1 id="truthLogo" className="target target--brand">
						<img className="logoMark" src="i/logos/truth-arts-mark.gif" alt="Truth Arts Animated Mark" title="Truth Arts Mark" />
						<span className="logoType">Truth Arts</span>
					</h1>
					{/* main navigation */}
					<nav id="bigNav">
						<a className="navItem target target--brand active" href="https://trutharts.com" title="Back to the Truth Arts Homepage">Home</a>
						<a className="navItem target target--brand" href="#about" title="Learn More about Truth Arts" onClick={scrollToAbout}>About</a>
						<a className="navItem target target--brand" href="https://blog.trutharts.com" target="_blank" rel="noreferrer" title="Visit the Truth Arts Blog">Truth Blog</a>
						<a className="button target target--brand" href="mailto:help@trutharts.com" title="Contact Truth Arts">ConTaCt Us</a>
					</nav>
				</header>
				{/* hero art */}
				<div className="art">
					{slides.map((slide, index) => (
						<div key={index} className={`slide ${index === currentSlide ? 'visible' : 'hidden'}`} style={{ transition: 'opacity 1s ease-in-out', opacity: index === currentSlide ? 1 : 0 }}>
							<img src={slide.src} alt={slide.alt} />
						</div>
					))}
				</div>
				{/* burn hero copy */}
				<div className="copy">
					<h2 className="screamer">
						We<br></br>
						Are<br></br>
						Truth.
					</h2>
				</div>
			</div>
			<main className="site">
				{/* intro */}
				<section id="intro">
					<div className="copy">
						<h3 className="screamer">We makE stufF.</h3>
						<p>We are a collective of <strong>high-powered creative mutant world-builders</strong>, here to tell stories so <strong>wild</strong>, <strong>fantastical</strong>, <strong>heart-wrenching</strong>, <strong>touching</strong>, and <strong>epically ridiculous</strong> that they can't not be believed.</p>
						<a className="button--big target target--brand" href="#about" title="About Truth Arts" onClick={scrollToAbout}>MeEt the Team</a>
					</div>
					{/* team preview */}
					<div id="teamPreview" ref={scrollRef}>
						{teammates.map((teammate, index) => (
							<div key={index} className="pfp teammate edge">
								<img src={teammate.src} alt={teammate.alt} loading="lazy" />
							</div>
						))}
					</div>
				</section>

				<section className="grid grid-2">
					{/* escape goblintown */}
					<a id="escapePane" href="https://www.roblox.com/games/18100828076/UPDATE-Escape-Goblintown#!/about" target="_blank" rel="noreferrer" title="Check out Escape Goblintown on Roblox" className="gridPane bigArt edge target target--gob hasToggle">
						<div className="art">
							<img src="i/escape.png" alt="" loading="lazy" />
						</div>
						<div className="words">
							<h5 className="label">You'RE It!</h5>
							<div className="copy">
								<h4 className="screamer">EscApe GoBLinTowN</h4>
								<p>Prepare to Escape Goblintown, or become a Goblin and tag the stray hoomans before they get away!</p>
							</div>
							<a className="button--big target target--gob" href="https://www.roblox.com/games/18100828076/UPDATE-Escape-Goblintown#!/about" title="Visit the Goblin Tag Website">Check it Out!</a>
						</div>
						<i className="toggle"></i>
					</a>

					{/* portals */}
					<a id="portalsPane" href="https://portals.trutharts.com" target="_blank" rel="noreferrer" title="Visit the Portals Website" className="gridPane edge target target--gob hasToggle">

						<h5 className="label">[ ReaDY? ]</h5>
						<div className="copy">
							<h4 className="screamer">Introducing Portals</h4>
							<p>Move your Truth NFTs easily between EVM chains and chase rewards all over the blockchain.</p>
						</div>
						<a className="button--big target target--gob" href="https://portals.trutharts.com" title="Visit the Goblin Tag Website">Check it Out!</a>
						<i className="toggle"></i>
						<div className="art">
							<img src="i/portals.png" alt="" loading="lazy" />
						</div>
					</a>
				</section>

				<section id="burn" className="solo">
					{/* operation: resurrection */}
					<a href="https://app.trutharts.com" target="_blank" rel="noreferrer" id="burnPane" className="gridPane bigPane edge target target--brand hasToggle">
						<div className="labelGroup">
							<h5 className="label">Burn Limit Reached!</h5>
						</div>
						<div className="copy">
							<h4 className="screamer">Operation: Resurrection</h4>
							<p>Burn your Truth Labs NFTs, receive future equity rights in Truth Arts, Inc., <strong>and keep the art forever</strong></p>
						</div>
						<a href="https://app.trutharts.com" target="_blank" rel="noreferrer" className="button--big target target--brand" title="Visit Operation: Resurrection">Visit Dashboard</a>
						<i className="toggle"></i>
					</a>
				</section>

				{/* goblintown stuff */}
				<section id="gobs">
					<div id="gobStack" className="gridStack">

						<div className="grid grid-2">
							{/* merch */}
							<a id="storePane" href="https://shop.goblintown.com/" target="_blank" rel="noreferrer" title="Visit the Merch Store" className="gridPane edge target target--gob hasToggle">
								<h5 className="label">Not naked</h5>

								<div className="copy inverse">
									<h4 className="screamer">MErch StoRe</h4>
									<p>Flaunt your goblin without going naked. Made with 100% Gob lint down, and ready to cover up your parts.</p>
								</div>
								<a className="button--big target target--gob" href="https://shop.goblintown.com/" target="_blank" rel="noreferrer" title="Visit the Merch Store">Shop Merch</a>
								<div id="horde">
									<img src="i/horde.png" alt="" loading="lazy" />
								</div>
								<i className="toggle"></i>
							</a>
							{/* goblintown */}
							<div id="gobPane" className="gridPane edge target target--gob">
								<h5 className="label">Hi, Hi!</h5>
								<div className="copy inverse">
									<h4 className="screamer">GObliNtown</h4>
									<p>The tingle you feel isn't because it's full of mayhem and silly goblin antics. It's because it's almost like the “real world.”</p>
								</div>
								<a className="button target target--gob disabled" href="#" title="Visit Goblintown">Bit Longer&hellip;</a>
								<div id="gobbie">
									<img src="i/gobbie.png" alt="" loading="lazy" />
								</div>
							</div>
						</div>
						{/* spit spots */}
						<a id="spitPane" href="https://www.spitspots.tv/" target="_blank" rel="noreferrer" title="Visit the Spit Spots Website" className="gridPane edge target target--gob hasToggle">
							<h5 className="label">Ooh&hellip; cONTent</h5>
							<div className="copy">
								<h4 className="screamer">Spit Spots</h4>
								<p>Dip your finger in the flavor of the gobbiest town on earth with short-form stories by Goblintown's creators and friends.</p>
							</div>
							<a className="button--big target target--gob" href="https://spitspots.tv" target="_blank" rel="noreferrer" title="Visit the Spit Spots Website">Tune in!</a>
							<i className="toggle"></i>
						</a>
					</div>
				</section>

				{/* gaming */}
				<section id="gameGrid" className="gridStack">
					<div className="grid grid-3">
						<div id="crashPane" className="gridPane bigArt edge target target--gob">
							<div className="art">
								<img src="i/crash.png" alt="" loading="lazy" />
							</div>
							<div className="words">
								<h5 className="label">TOOT BOOSTER, Go!</h5>
								<div className="copy">
									<h4 className="screamer">Goblin Crash</h4>
									<p>Rev up your cart and find that sweet spot between exploding and&hellip; well&hellip; also exploding, but differently. FOREVER.</p>
								</div>

								<a className="button target target--gob disabled" href="#" title="Visit the Goblin Crash Website">Soonish</a>
							</div>
						</div>
						<div id="risePane" className="gridPane edge target target--gob">
							<div className="art">
								<img src="i/idle.png" alt="" loading="lazy" />
							</div>
							<h5 className="label">DaS GOBITAL</h5>
							<div className="copy">
								<h4 className="screamer">The Rise of BiG MiSTER</h4>
								<p>In this cozy idle experience, BiG MiSTER works to undermine Goblintown with capitalism. It doesn’t go well.</p>
							</div>
							<a className="button target target--gob disabled" href="#" title="Visit the Rise of BiG MiSTER Website">Soon&hellip;</a>

						</div>
						<div id="deliveryPane" className="gridPane edge target target--gob">
							<h5 className="label">23+ ZILLION SWERVED</h5>
							<div className="copy">
								<h4 className="screamer">Goblintown Burger Delivery</h4>
								<p>Someone forgot to set the brake on the McGoblin Burger truck. Again. Unlock a slew of wild characters and save the day!</p>
							</div>
							<a className="button target target--gob disabled" href="#" title="Visit the Goblinmode Burger Delivery Website">SorTa SoOn</a>
							<div className="art">
								<img src="i/delivery.png" alt="" loading="lazy" />
							</div>
						</div>
					</div>
				</section>

				{/* graphic novel */}
				<section id="gobNovel" className="solo">
					<div id="novelPane" className="gridPane bigPane target target--gob">
						<h5 className="label">Physical + Digital</h5>
						<div className="copy inverse">
							<h4 className="screamer">ThE BIG BoUnCINg BOoTY OF BADNEsS</h4>
							<p>Truth Arts presents: Goblintown - Part of the Twenty-Three Zillion Cycle</p>
						</div>
						<a className="button target target--gob disabled" href="#" title="Explore the Graphic Novel">WhEn it's ready</a>
						<div className="art">
							<img src="i/novelPanel.gif" alt="" loading="lazy" />
						</div>
					</div>
				</section>

				{/* lev + secret */}
				<section className="grid grid-2">
					{/* lev's kids */}
					<a href="https://www.the187.xyz/" target="_blank" rel="noreferrer" title="Visit the Leviathan's Children Website" id="levPane" className="gridPane edge target target--brand hasToggle">
						<h5 className="label">DEeP AnD WIDe</h5>
						<div className="copy">
							<h4 className="screamer">Leviathan's Children</h4>
							<p>Plumb the depths of Truth Arts' expansive and evolving universe - a universe that embraces community ownership and shared storytelling.</p>
						</div>
						<a className="button--big target target--gob" href="https://www.the187.xyz/" target="_blank" rel="noreferrer" title="Visit the Leviathan's Children Website">MEet thE ChIldReN</a>
						<div className="art">
							<img src="i/children.png" alt="" loading="lazy" />
						</div>
						<i className="toggle"></i>

					</a>
					{/* meme depot */}
					<a href="https://memedepot.com" target="_blank" rel="noreferrer" title="Visit Meme Depot">
						<div id="secretPane" className="gridPane edge target target--brand hasToggle">
							<h5 className="label">oUT OF tHE BOX</h5>
							<div className="copy">
								<h4 className="screamer">Your New Home for Memes</h4>
								<p>Be funnier than you are! Make strategic use of pop culture, off-kilter references, and an endless wave of people more erudite than you.</p>
							</div>
							<a className="button--big target target--gob" target="_blank" rel="noreferrer" href="https://memedepot.com" title="Visit Meme Depot">Visit Meme Depot</a>
							<div className="art">
								<img src="i/drip.png" alt="" loading="lazy" />
							</div>
							<i className="toggle"></i>
						</div>
					</a>
				</section>

				{/* web3 */}
				<section id="digitalArt" className="gridHeader">
					<h3 className="screamer">Digital Collectibles</h3>
				</section>
				<section id="digitalArtGrid" className="scrollGrid">
					{projects.map((project, index) => (
						<a href={project.url} key={index} className={`project target target--${project.target}`} target="_blank" rel="noreferrer" title={`Visit the ${project.name} Website`}>
							<div className="projectImage"></div>
							<div className="projectName">{project.name}</div>
							<div className="projectDescription">{project.description}</div>
							<div className="projectLinks">
								{/* Dynamic class names for different targets */}
								<div className="projectSocial">
									<a href={`https://www.twitter.com/${project.social}`} className={`socialLink target target--${project.target}`} target="_blank" rel="noreferrer"></a>
									<a href={project.marketplace} className={`socialLink marketplaceLink target target--${project.target}`} target="_blank" rel="noreferrer"></a>
								</div>
							</div>
						</a>
					))}
				</section>



				{/* about header */}
				<section id="about" className="gridHeader" ref={aboutRef}>
					<h2 className="screamer sneaky">Meet the Truth Team</h2>
					<p>The modern entertainment landscape is fragmented and multifaceted. Truth Arts is creating a modern fantasy and science fiction universe that lovingly embraces various forms of entertainment, technology, and IP as aspects of a single, cohesive whole.</p>
				</section>
				{/* about */}
				<section id="teamGrid" className="grid grid-4">
					{teammates.map((teammate, index) => (
						<div key={index} className="pfp teammate edge">
							<img src={teammate.src} alt={teammate.alt} title={teammate.alt} loading="lazy" />
							<h4 className="screamer teammateName">{teammate.name}</h4>
						</div>
					))}
				</section>
				{/* article header */}
				{/* TODO: update blog */}
				<section className="gridHeader">
					<h2 className="screamer sneaky">From The Blog</h2>
				</section>
				{/* articles */}
				<section id="blogGrid">
					<Article
						title="Introducing Portals: Future-Proofing Our NFT Collections"
						link="https://blog.trutharts.com/introducing-portals/"
						date="6.24.24"
					/>
					<Article
						title="Introducing Goblintown’s First Video Game: Escape Goblintown, Now on Roblox"
						link="https://blog.trutharts.com/escape-goblintown-launch/"
						date="6.21.24"
					/>
					<Article
						title="Introducing Meme Depot"
						link="https://blog.trutharts.com/introducing-meme-depot/"
						date="5.29.24"
					/>
				</section>
			</main>
			<footer id="foot">
				<div id="contact" className="wigglySides hasToggle ">
					<h3 className="screamer target target--brand"><a href="mailto:help@trutharts.com">help@trutharts.com</a></h3>
					<i className="toggle"></i>
				</div>
				<div id="footContent">
					<div id="footAbout">
						<div id="footLogo" className="target target--brand">
							<img className="logoMark" src="i/logos/truth-arts-mark.gif" alt="Truth Arts Animated Mark" title="Truth Arts Mark" />
						</div>
						<p>Truth Arts is a collective of <strong>high-powered creative mutant world-builders</strong>, here to tell stories so <strong>wild</strong>, <strong>fantastical</strong>, <strong>heart-wrenching</strong>, <strong>touching</strong>, and <strong>epically ridiculous</strong> that they can't not be believed.</p>
					</div>
					<div id="footSocial" className="edge">
						<h3>Truth Social</h3>
						<a id="xLink" href="https://twitter.com/truth" target="_blank" rel="noreferrer" className="socialLink target target--brand edge" title="Follow Truth Arts on X">
							<i className='socialIcon'></i>
							<h5>Follow on X</h5>
						</a>
						<a id="discordLink" href="https://discord.gg/illuminati" target="_blank" rel="noreferrer" className="socialLink target target--brand edge" title="Join the Truth Arts Discord">
							<i className='socialIcon'></i>
							<h5>Join the Discord</h5>
						</a>
						<a id="republicLink" href="https://republic.com/truth-arts" target="_blank" rel="noreferrer" className="socialLink target target--brand edge" title="View the Truth Arts Deal Page on Republic">
							<i className='socialIcon'></i>
							<h5>Visit Republic</h5>
						</a>
					</div>
				</div>
				<div className="wiggleDivide">
					<img src="i/wiggle-line.png" alt="" loading="lazy" />
				</div>
				<div id="footFoot">
					<div id="colo">&copy;2025 Truth Arts Inc. All Rights Reserved.</div>
					<div id="legal">
						<a href="https://truth-public-content.s3.us-east-2.amazonaws.com/Privacy+Policy_2.21.24.pdf" className="target target--brand" target="_blank" rel="noreferrer" title="Read the Truth Arts Privacy Policy">Privacy Policy</a>
						<a href="https://truth-public-content.s3.us-east-2.amazonaws.com/Truth+Arts_Terms+and+Conditions.pdf" className="target target--brand" target="_blank" rel="noreferrer" title="Read the Truth Arts Terms and Conditions">Terms and Conditions</a>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default Home;
